<div class="flex flex-row justify-between px-6 bg-primary">
	<div class="flex flex-col pt-2 text-xl text-on-primary justify-center">Add Body Fat Measurement</div>
	<div class="flex flex-col justify-center">
		<button (click)="cancel()" class="bg-accent text-on-primary my-2" mat-icon-button>
			<mat-icon class="text-on-accent">close</mat-icon>
		</button>
	</div>
</div>

<mat-select class="flex flex-col p-4 text-lg bg-default" [(value)]="selectedBodyFatCalculationType">
	@for (bodyFatCalculationType of bodyFatCalculationTypeList | keyvalue; track bodyFatCalculationType) {
		<mat-option [value]="bodyFatCalculationType.value">
			{{ bodyFatCalculationType.key }}
		</mat-option>
	}
</mat-select>

@switch (selectedBodyFatCalculationType) {
	@case (bodyFatCalculationTypes.Manual) {
		<client-detail-body-composition-body-fat-measurement-sidebar-create-manual
			[bodyFatCalculationTypeList]="bodyFatCalculationTypeList"
			(onCreated)="bodyFatMeasurementCreated($event)"
			(onCancel)="cancel()">
		</client-detail-body-composition-body-fat-measurement-sidebar-create-manual>
	}
	@case (bodyFatCalculationTypes.JacksonPollock3Site) {
		<client-detail-body-composition-body-fat-measurement-sidebar-create-jackson-pollock-3-site
			[clientGender]="client()().genderId"
			[bodyFatCalculationTypeList]="bodyFatCalculationTypeList"
			(onCreated)="bodyFatMeasurementCreated($event)"
			(onCancel)="cancel()">
		</client-detail-body-composition-body-fat-measurement-sidebar-create-jackson-pollock-3-site>
	}
	@case (bodyFatCalculationTypes.JacksonPollock7Site) {
		<client-detail-body-composition-body-fat-measurement-sidebar-create-jackson-pollock-7-site
			[clientGender]="client()().genderId"
			[bodyFatCalculationTypeList]="bodyFatCalculationTypeList"
			(onCreated)="bodyFatMeasurementCreated($event)"
			(onCancel)="cancel()">
		</client-detail-body-composition-body-fat-measurement-sidebar-create-jackson-pollock-7-site>
	}
}
