import { Component, OnChanges, OnDestroy, ViewEncapsulation, inject, input, output, viewChild } from '@angular/core';
import { FormBuilder, FormGroup, FormGroupDirective, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';

import { BodyFatCalculationType } from '@fitness-central/api/client/body-composition/body-fat/body-fat-measurement/body-fat-calculation-type.enum';
import { ClientBodyFatMeasurementCreateRequestModel } from '@fitness-central/api/client/body-composition/body-fat/body-fat-measurement/body-fat-measurement-create-request.model';
import { ClientBodyFatMeasurementResponseModel } from '@fitness-central/api/client/body-composition/body-fat/body-fat-measurement/body-fat-measurement-response.model';
import { ClientBodyFatSiteMeasurementRequestModel } from '@fitness-central/api/client/body-composition/body-fat/body-fat-site-measurement/body-fat-site-measurement-request.model';
import { ClientBodyFatSiteMeasurementType } from '@fitness-central/api/client/body-composition/body-fat/body-fat-site-measurement/body-fat-site-measurement-type.enum';
import { GenderType } from '@fitness-central/api/general/gender-type/gender-type.enum';

import { Subject } from 'rxjs';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonModule } from '@angular/material/button';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';

import { NumberValidators } from '@abp/validators/number-validators';

@Component({
	selector: 'client-detail-body-composition-body-fat-measurement-sidebar-create-jackson-pollock-7-site',
	templateUrl: './jackson-pollock-7-site.component.html',
	encapsulation: ViewEncapsulation.None,
	imports: [FormsModule, ReactiveFormsModule, MatFormFieldModule, MatInputModule, MatDatepickerModule, MatButtonModule, MatTooltipModule, MatProgressSpinnerModule]
})

export class ClientDetailBodyCompositionBodyFatMeasurementSidebarCreateJacksonPollock7SiteComponent implements OnChanges, OnDestroy
{
	private readonly _formBuilder = inject(FormBuilder);

	readonly clientGender = input<GenderType>(undefined);
	readonly bodyFatCalculationTypeList = input<Record<number, string>>(undefined);

	readonly onCreated = output<ClientBodyFatMeasurementCreateRequestModel>();
	readonly onCancel = output();

	readonly formGroupDirective = viewChild(FormGroupDirective);

	public selectedBodyFatCalculationType : BodyFatCalculationType = BodyFatCalculationType.Manual;
	public bodyFatCalculationTypes : typeof BodyFatCalculationType = BodyFatCalculationType;

	public showAlert: boolean = false;

	public bodyWeightMeasurement: ClientBodyFatMeasurementResponseModel = new ClientBodyFatMeasurementResponseModel();
	public bodyFatMeasurementForm : FormGroup;

	public genderTypes : typeof GenderType = GenderType;

	private _unsubscribeAll: Subject<void>  = new Subject<void>();

	public ngOnChanges(): void
	{
		this.buildForm();
	}

	public create()
	{
		const bodyFatMeasurement = new ClientBodyFatMeasurementCreateRequestModel();

		bodyFatMeasurement.bodyFatCalculationTypeId = BodyFatCalculationType.JacksonPollock7Site;
		bodyFatMeasurement.measurementDate = this.bodyFatMeasurementForm.get('measurementDate').value;

		const chest = new ClientBodyFatSiteMeasurementRequestModel();
		chest.bodyFatSiteMeasurementTypeId = ClientBodyFatSiteMeasurementType.Chest;
		chest.value = this.bodyFatMeasurementForm.get('chest').value;
		bodyFatMeasurement.bodyFatSiteMeasurements.push(chest);

		const abdomen = new ClientBodyFatSiteMeasurementRequestModel();
		abdomen.bodyFatSiteMeasurementTypeId = ClientBodyFatSiteMeasurementType.Abdomen;
		abdomen.value = this.bodyFatMeasurementForm.get('abdomen').value;
		bodyFatMeasurement.bodyFatSiteMeasurements.push(abdomen);

		const thigh = new ClientBodyFatSiteMeasurementRequestModel();
		thigh.bodyFatSiteMeasurementTypeId = ClientBodyFatSiteMeasurementType.Thigh;
		thigh.value = this.bodyFatMeasurementForm.get('thigh').value;
		bodyFatMeasurement.bodyFatSiteMeasurements.push(thigh);

		const tricep = new ClientBodyFatSiteMeasurementRequestModel();
		tricep.bodyFatSiteMeasurementTypeId = ClientBodyFatSiteMeasurementType.Triceps;
		tricep.value = this.bodyFatMeasurementForm.get('tricep').value;
		bodyFatMeasurement.bodyFatSiteMeasurements.push(tricep);

		const axilla = new ClientBodyFatSiteMeasurementRequestModel();
		axilla.bodyFatSiteMeasurementTypeId = ClientBodyFatSiteMeasurementType.Axilla;
		axilla.value = this.bodyFatMeasurementForm.get('axilla').value;
		bodyFatMeasurement.bodyFatSiteMeasurements.push(axilla);

		const subscapula = new ClientBodyFatSiteMeasurementRequestModel();
		subscapula.bodyFatSiteMeasurementTypeId = ClientBodyFatSiteMeasurementType.Subscapula;
		subscapula.value = this.bodyFatMeasurementForm.get('subscapula').value;
		bodyFatMeasurement.bodyFatSiteMeasurements.push(subscapula);

		const suprailiac = new ClientBodyFatSiteMeasurementRequestModel();
		suprailiac.bodyFatSiteMeasurementTypeId = ClientBodyFatSiteMeasurementType.Suprailiac;
		suprailiac.value = this.bodyFatMeasurementForm.get('suprailiac').value;
		bodyFatMeasurement.bodyFatSiteMeasurements.push(suprailiac);

		this.resetForm();
		this.onCreated.emit(bodyFatMeasurement);
	}

	public cancel()
	{
		this.resetForm();
		this.onCancel.emit();
	}

	public ngOnDestroy(): void
	{
		this._unsubscribeAll.next();
		this._unsubscribeAll.complete();
	}

	private buildForm()
	{
		this.bodyFatMeasurementForm = this._formBuilder.group
		(
			{
				chest: ['', [Validators.required, NumberValidators.decimal(1, 3, 0, 2)]],
				abdomen: ['', [Validators.required, NumberValidators.decimal(1, 3, 0, 2)]],
				thigh: ['', [Validators.required, NumberValidators.decimal(1, 3, 0, 2)]],
				tricep: ['', [Validators.required, NumberValidators.decimal(1, 3, 0, 2)]],
				axilla: ['', [Validators.required, NumberValidators.decimal(1, 3, 0, 2)]],
				subscapula: ['', [Validators.required, NumberValidators.decimal(1, 3, 0, 2)]],
				suprailiac: ['', [Validators.required, NumberValidators.decimal(1, 3, 0, 2)]],
				measurementDate: [new Date()]
			}
		);
	}

	private resetForm()
	{
		setTimeout
		(
			() =>
			{
				this.buildForm();
				this.formGroupDirective().resetForm();
			}
		);
	}
}
