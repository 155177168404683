import { KeyValuePipe } from '@angular/common';
import { Component, OnDestroy, OnInit, Signal, ViewEncapsulation, inject, input, output } from '@angular/core';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';

import { BodyFatCalculationType } from '@fitness-central/api/client/body-composition/body-fat/body-fat-measurement/body-fat-calculation-type.enum';
import { ClientBodyFatMeasurementCreateRequestModel } from '@fitness-central/api/client/body-composition/body-fat/body-fat-measurement/body-fat-measurement-create-request.model';
import { ClientBodyFatMeasurementResponseModel } from '@fitness-central/api/client/body-composition/body-fat/body-fat-measurement/body-fat-measurement-response.model';
import { ClientBodyFatMeasurementService } from '@fitness-central/api/client/body-composition/body-fat/body-fat-measurement/body-fat-measurement.service';
import { ClientResponseModel } from '@fitness-central/api/client/client/client-response.model';

import { Subject, takeUntil } from 'rxjs';

import { ClientDetailBodyCompositionBodyFatMeasurementSidebarCreateJacksonPollock3SiteComponent } from './jackson-pollock-3-site/jackson-pollock-3-site.component';
import { ClientDetailBodyCompositionBodyFatMeasurementSidebarCreateJacksonPollock7SiteComponent } from './jackson-pollock-7-site/jackson-pollock-7-site.component';
import { ClientDetailBodyCompositionBodyFatMeasurementSidebarCreateManualComponent } from './manual/manual.component';

@Component({
	selector: 'client-detail-body-composition-body-fat-measurement-sidebar-create',
	templateUrl: './create.component.html',
	encapsulation: ViewEncapsulation.None,
	imports: [
		FormsModule,
		ReactiveFormsModule,
		MatButtonModule,
		MatIconModule,
		MatSelectModule,
		MatOptionModule,
		ClientDetailBodyCompositionBodyFatMeasurementSidebarCreateManualComponent,
		ClientDetailBodyCompositionBodyFatMeasurementSidebarCreateJacksonPollock3SiteComponent,
		ClientDetailBodyCompositionBodyFatMeasurementSidebarCreateJacksonPollock7SiteComponent,
		KeyValuePipe
	]
})

export class ClientDetailBodyCompositionBodyFatMeasurementSidebarCreateComponent implements OnInit, OnDestroy
{
	readonly _bodyFatMeasurementService = inject(ClientBodyFatMeasurementService);

	readonly client = input<Signal<ClientResponseModel>>(undefined);

	readonly onCreated = output<ClientBodyFatMeasurementResponseModel>();
	readonly onCancel = output();

	public selectedBodyFatCalculationType: BodyFatCalculationType = BodyFatCalculationType.Manual;
	public bodyFatCalculationTypes: typeof BodyFatCalculationType = BodyFatCalculationType;

	public bodyFatCalculationTypeList: Record<string, number>;

	public showAlert: boolean = false;

	public bodyWeightMeasurement: ClientBodyFatMeasurementResponseModel = new ClientBodyFatMeasurementResponseModel();
	public bodyWeightMeasurementForm: FormGroup;

	private _unsubscribeAll: Subject<void>  = new Subject<void>();

	public ngOnInit(): void
	{
		this.getBodyFatCalculationTypes();
	}

	public bodyFatMeasurementCreated(bodyFatMeasurement: ClientBodyFatMeasurementCreateRequestModel)
	{
		bodyFatMeasurement.clientId = this.client()().clientId;
		bodyFatMeasurement.age = this.calculateAge(this.client()().dateOfBirth);
		bodyFatMeasurement.gender = this.client()().genderId;

		this._bodyFatMeasurementService
			.create(bodyFatMeasurement)
			.pipe(takeUntil(this._unsubscribeAll))
			.subscribe
			(
				{
					next: (bodyFatMeasurement) =>
					{
						this.onCreated.emit(bodyFatMeasurement);
					}
				}
			);
	}

	public cancel()
	{
		this.onCancel.emit();
	}

	public ngOnDestroy(): void
	{
		this._unsubscribeAll.next();
		this._unsubscribeAll.complete();
	}

	private calculateAge(dateOfBirth: Date): number
	{
		const currentDate = new Date();
		const date = new Date(dateOfBirth);

		let age = 0;

		if (dateOfBirth !== undefined || dateOfBirth !== null)
		{
			age = Math.floor((Date.UTC(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate()) - Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())) / (1000 * 60 * 60 * 24 * 365));
		}

		return age;
	}

	private getBodyFatCalculationTypes()
	{
		this.bodyFatCalculationTypeList =
		{
			'Manual Entry': 1,
			'Jackson-Pollock 3-Site': 2,
			'Jackson-Pollock 7-Site': 3
		}
	}
}
