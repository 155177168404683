import { Injectable } from '@angular/core';

import { BaseService } from '@fitness-central/api/base.service';

import { environment } from 'environments/environment';

import { Observable } from 'rxjs';

import { ClientBodyWeightCreateRequestModel } from './body-weight-create-request.model';
import { ClientBodyWeightResponseModel } from './body-weight-response.model';

@Injectable
(
	{
		providedIn: 'root'
	}
)

export class ClientBodyWeightService extends BaseService
{
	public constructor
	()
	{
		super();
	}

	public getByClientId(clientId : string, startDate : Date, endDate : Date) : Observable<ClientBodyWeightResponseModel[]>
	{
		const startDateString = super.convertToLuxonDateTime(startDate);
		const endDateString = super.convertToLuxonDateTime(endDate);

		const url = `${ environment.baseUrl  }/client/body-weight-measurements/client/${ clientId }?startDate=${ startDateString }&endDate=${ endDateString }`;

		return this.httpClient.get<ClientBodyWeightResponseModel[]>(url);
	}

	public get(bodyWeightId: string) : Observable<ClientBodyWeightResponseModel>
	{
		const url = `${ environment.baseUrl  }/client/body-weight-measurement/${  bodyWeightId }`;

		return this.httpClient.get<ClientBodyWeightResponseModel>(url);
	}

	public create(bodyWeight: ClientBodyWeightCreateRequestModel): Observable<ClientBodyWeightResponseModel>
	{
		const url = `${ environment.baseUrl  }/client/body-weight-measurement`;

		return this.httpClient.post<ClientBodyWeightResponseModel>(url, bodyWeight);
	}

	public update(bodyWeight: ClientBodyWeightResponseModel): Observable<ClientBodyWeightResponseModel>
	{
		const url = `${ environment.baseUrl  }/client/body-weight-measurement/${  bodyWeight.bodyWeightMeasurementId }`;

		return this.httpClient.put<ClientBodyWeightResponseModel>(url, bodyWeight)
	}

	public delete(bodyWeightId: string): Observable<ClientBodyWeightResponseModel>
	{
		const url = `${ environment.baseUrl  }/client/body-weight-measurement/${  bodyWeightId }`;

		return this.httpClient.delete<ClientBodyWeightResponseModel>(url)
	}
}
