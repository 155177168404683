import { Component, OnDestroy, OnInit, ViewEncapsulation, inject, input, output, viewChild } from '@angular/core';
import { FormBuilder, FormGroup, FormGroupDirective, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';

import { ClientBodyWeightResponseModel } from '@fitness-central/api/client/body-composition/body-weight/body-weight-response.model';
import { ClientBodyWeightService } from '@fitness-central/api/client/body-composition/body-weight/body-weight.service';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

import { MatTooltipModule } from '@angular/material/tooltip';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { NumberValidators } from '@abp/validators/number-validators';

@Component
(
	{
		selector: 'client-detail-body-composition-body-weight-sidebar-create',
		templateUrl: './create.component.html',
		encapsulation: ViewEncapsulation.None,
		imports: [
			MatButtonModule,
			MatIconModule,
			FormsModule,
			ReactiveFormsModule,
			MatFormFieldModule,
			MatInputModule,
			MatDatepickerModule,
			MatTooltipModule,
			MatProgressSpinnerModule
		]
	}
)

export class ClientDetailBodyCompositionBodyWeightSidebarCreateComponent implements OnInit, OnDestroy
{
	private _formBuilder = inject(FormBuilder);
	private _bodyWeightMeasurementService = inject(ClientBodyWeightService);

	readonly clientId = input<string>(undefined);

	readonly onCreated = output<ClientBodyWeightResponseModel>();
	readonly onCancel = output();

	readonly formGroupDirective = viewChild(FormGroupDirective);

	public bodyWeightMeasurement: ClientBodyWeightResponseModel = new ClientBodyWeightResponseModel();
	public bodyWeightMeasurementForm : FormGroup;

	private _unsubscribeAll: Subject<void>  = new Subject<void>();

	public ngOnInit(): void
	{
		this.buildForm();
	}

	private buildForm()
	{
		this.bodyWeightMeasurementForm = this._formBuilder.group
		(
			{
				clientId: [this.clientId()],
				value: ['', [Validators.required, NumberValidators.decimal(1, 3, 0, 2)]],
				measurementDate: [new Date()]
			}
		);
	}

	public create()
	{
		if (this.bodyWeightMeasurementForm.valid)
		{
			this.bodyWeightMeasurementForm.disable();

			this._bodyWeightMeasurementService
				.create(this.bodyWeightMeasurementForm.value)
				.pipe(takeUntil(this._unsubscribeAll))
				.subscribe
				(
					{
						next: (bodyWeightMeasurement) =>
						{
							setTimeout
							(
								() =>
								{
									this.buildForm();
									this.formGroupDirective().resetForm();
								}
							);

							this.onCreated.emit(bodyWeightMeasurement);
						}
					}
				);
		}
	}

	public cancel()
	{
		setTimeout
		(
			() =>
			{
				this.buildForm();
				this.formGroupDirective().resetForm();
			}
		);

		this.onCancel.emit();
	}

	public ngOnDestroy(): void
	{
		this._unsubscribeAll.next();
		this._unsubscribeAll.complete();
	}
}
